/**
 * A helper script for users embedding the form.
 *
 * It exists mostly to provide the `iframe-resizer` client script.
 */

import { iframeResize } from 'iframe-resizer';

const INCIDENT_FORM = document.getElementById('incident-form');

window.addEventListener('load', function () {
    iframeResize({}, INCIDENT_FORM);
});
